import React from 'react';
import './style.css';

import Main from "./Main";

export default function App() {
  return (
    <div>
      <h1>Hello StackBlitz!</h1>
      <p>Start editing to see some magic happen :)</p>

      <Main/>




    </div>
  );
}
